
import { Vue, Component } from 'vue-property-decorator'
import { EmailAPI } from '@/api'

@Component
export default class Support extends Vue {
  //
  // REACTIVE PROPERTIES
  //
  subject = ''
  name = ''
  email = ''
  institution = ''
  message = ''
  show = true
  sending = false
  success = false
  failure = false
  dismissSecs = 5
  dismissCountDown = 0

  //
  // EVENT HANDLERS
  //
  async onSubmit(e: Event) {
    e.preventDefault()

    this.$validator.validateAll().then(async(result) => {
      if (!result) {
        return
      }

      if (this.sending) return

      this.sending = true

      try {
        await EmailAPI.sendSupportEmail(
          this.subject,
          this.name,
          this.email,
          this.institution,
          this.message
        )

        this.clearForm()
        this.success = true
        this.dismissCountDown = this.dismissSecs
      } catch (err) {
        this.failure = true
      }

      this.sending = false
    })
  }

  onReset(e: Event) {
    e.preventDefault()
    this.clearForm()
  }

  countDownChanged(dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown
  }

  //
  // METHODS
  //
  clearForm() {
    this.subject = ''
    this.name = ''
    this.email = ''
    this.institution = ''
    this.message = ''

    // Trick to reset/clear native browser form validation state
    this.show = false
    this.$nextTick(() => {
      this.show = true
      this.$validator.reset()
    })

    this.success = false
    this.failure = false
  }

  validateState(ref: string) {
    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.veeFields[ref] &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return !this.veeErrors.has(ref)
    }

    return null
  }
}
